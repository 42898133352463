var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_indexPage"
  }, [_vm._v(" " + _vm._s(_vm.taskId) + " "), _c('pop-reward-result', {
    attrs: {
      "show": _vm.isPopRewardResultShow,
      "select-row": _vm.selectRow,
      "showBtn": false,
      "title": _vm.$lang('Congratulations on getting')
    },
    on: {
      "update:show": function ($event) {
        _vm.isPopRewardResultShow = $event;
      },
      "accept": _vm.onAccept
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };