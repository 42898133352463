import Vue from "vue";
import vailcode from "@utils/errcode";
import lang from "@i18n/i18nOption.json";
const { langDefault, langList } = lang;
// import web3Wallet from "@web3Wallet/index";
import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
export default {
  namespaced: true,
  state: {
    isMobile: true,
    isLang: "En",
    setting: {},
    token: "",
    taskId:0,
    adv:0,
    balance: {}, //代币余额
    tonConnect: false,
    theme: web3Config.theme,
    taskCount:0
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setTaskId: (state, payload) => {
      state.taskId = payload;
    },
    setAdv: (state, payload) => {
      state.adv = payload;
    },
    setTheme: (state, payload) => {
      state.theme = payload;
    },
    setBtnTop(state, payload) {
      state.btnTop = payload;
    },
    SETLANG: (state, payload) => {
      state.isLang = payload;
    },
    SET_ISMOBILE: (state, payload) => {
      state.isMobile = payload;
    },
    SETSETTING: (state, payload) => {
      state.setting = payload;
    },

    CLEAR_CHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    SET_CHECKWALLETHANDLE: (state, payload) => {
      state.checkWalletHandle = payload;
    },
    CLEARCHECKWALLETHANDLE: (state) => {
      clearInterval(state.checkWalletHandle);
    },
    SET_BALANCE: (state, payload) => {
      // console.log('payload', payload);
      for (const key in payload) {
        Vue.prototype.$set(state.balance, key, payload[key]);
      }
    },
    setTonConnect: (state, payload) => {
      state.tonConnect = payload;
    },
  },
  actions: {
    //获取余额
    getSetting: async ({ commit }) => {
      const rs = await Vue.prototype.$http.post("/system/init", {});
      vailcode(rs, () => {
        commit("SETSETTING", rs.data);
      });
    },
  },
  getters: {},
};
