import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    userData: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...walletVuex.mapActions(['getSetting']),
    ...walletVuex.mapMutations(['setToken', 'setTaskId', 'setAdv']),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  },
  async mounted() {
    console.log("this.WebApp.initData", this.WebApp);
    await this.getSetting();
    let start_param = this.WebApp.initDataUnsafe.start_param || '';
    if (!start_param) {
      //this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}/app?startapp=reload`)
      //this.WebApp.close();
      this.$router.push("/newIndex");
    } else {
      start_param = start_param.split('=');
      let token = start_param[0];
      let taskId = start_param[1].split("-")[0] || 0;
      let adv = start_param[1].split("-")[1] || 0;
      if (!start_param[0] || !start_param[1]) {
        this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}/app?startapp=reload`);
        this.WebApp.close();
        return;
      }
      this.setToken(token);
      this.setTaskId(taskId);
      this.setAdv(adv);
    }
    this.isLoading = true;
  }
};