import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "index",
  computed: {
    ...walletVuex.mapState(["setting", "theme", 'taskId', 'adv'])
  },
  data() {
    return {
      checkNum: 0,
      selectRow: {},
      isPopRewardResultShow: false
    };
  },
  methods: {
    ...taskVuex.mapActions(['getTaskInfo']),
    preventDefault() {
      this.$nextTick(() => {
        const center = document.querySelector("._indexPage");
        if (!center) return;
        console.log("center", center);
        center.addEventListener("touchmove", function (event) {
          event.preventDefault();
        }, {
          passive: false
        });
      });
    },
    async checkTask(item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        this.isPopRewardResultShow = true;
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkTask(item, adv);
        }, 2000);
      }
    },
    onAccept() {
      this.isPopRewardResultShow = false;
      this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}/app?startapp=reload`);
      this.WebApp.close();
      this.$loading.hide();
    }
  },
  async mounted() {
    this.preventDefault();
    this.$nextTick(async () => {
      if (!this.taskId) {
        return;
      }
      this.$loading.show();
      await this.getTaskInfo([this.taskId, async data => {
        this.selectRow = {
          value: data.reward,
          stock: data.stock,
          unit: this.setting.coin,
          type: "tapcoins"
        };
        const AdController = await window.Adsgram.init({
          blockId: data.url
        });
        await AdController.show().then(result => {
          // user watch ad till the end
          // your code to reward user
          this.$loading.hide();
          if (result.done && !data.completed) this.checkTask(data, this.adv);else this.isPopRewardResultShow = true;
        }).catch(result => {
          // user skipped video or get error during playing ad
          // do nothing or whatever you want
          console.log(result);
          this.WebApp.openTelegramLink(`https://t.me/${this.setting.botname}/app?startapp=reload`);
          this.WebApp.close();
          this.$loading.hide();
        });
        this.$loading.hide();
      }, res => {}, this.adv, 0]);
    });
  }
};