import axios from "axios";
import Vue from "vue";
import loadConfig from "@Web3WalletConfig/config";
import store from "@store/index";
import qs from "qs";
import cookie from "js-cookie";

const Axios = axios.create({
  timeout: 10000, // 请求超时时间
});

Axios.defaults.baseURL = loadConfig.http_host;
// 添加请求拦截器
Axios.interceptors.request.use(
  (config) => {
    
    //console.log(config.data)
    let token = store.state['wallet']['token'] || '';
    if (token) {
      //config.headers["authorization"] = "Bearer " + token;
      config.data['_token'] = token;
    }
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* 响应拦截器 */
Axios.defaults.validateStatus = (status) => {
  // 根据状态码返回结果
  if (/^(2|3)\d{2}$/.test(status)) {
    return true;
  } else {
    console.error(`Error: ${status} error`);
    return false;
  }
};
// 添加响应拦截器
Axios.interceptors.response.use(
  (res) => {
    // 对响应数据做点什么
    return res.data;
  },
  (err) => {
    /* 没网 */
    if (!window.navigator.onLine) {
      //跳转到断网页面
      //Vue.toasted.error(`${Vue.prototype.$lang("Error: Network exception")}`);
      return Promise.resolve(`${Vue.prototype.$lang("Error: Network exception")}`);
    } else {
      //if (err.response.status !== 200) {
        let retryCount = 0;  
        if(err.config.retryCount > 0){
          retryCount = err.config.retryCount
        }
        const maxRetries = 2;  
        function retryRequest() {  
          if (loadConfig.ext_http_host.constructor === Array) {
            let idx = Math.floor(Math.random() * (loadConfig.ext_http_host.length - 1));
            err.config.baseURL = loadConfig.ext_http_host[idx];
          } else {
            err.config.baseURL = loadConfig.ext_http_host;
          }
          return new Promise((resolve, reject) => {  
            setTimeout(() => {  
              console.log(`r c: ${retryCount + 1}`);  
              if (retryCount >= maxRetries) {  
                resolve(err);  
              } else {  
                retryCount++;  
                let nConfig = err.config
                nConfig.retryCount = retryCount
                nConfig.data = qs.parse(nConfig.data)
                Axios(nConfig).then(resolve).catch(reject);  
              }  
            }, 1000); // 等待1秒后再重试  
          });  
        } 
        return retryRequest();  
      // }
      // else if(err.data){
      //   //Vue.toasted.error(`${Vue.prototype.$lang(err.data)}`);
      //   console.log('err.data:',err.data)
      //   return Promise.resolve(err.data);
      // }
      // else{
      //   //Vue.toasted.error(`${Vue.prototype.$lang(err.message)}`);
      //   console.log('err:',err)
      //   return Promise.resolve(err.message);
      // }
    }
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (Vue) =>
    Object.defineProperty(Vue.prototype, "$http", {
      value: Axios,
    }),
  Axios,
};
