import { createNamespacedHelpers } from "vuex";
import cookie from "js-cookie";
export default {
  name: "index",
  computed: {},
  data() {
    return {
      cacheKey: "Coins:" + this.WebApp.initDataUnsafe.user.id,
      coin: 0
    };
  },
  mounted() {
    this.$loading.hide();
    this.coin = cookie.get(this.cacheKey) || 0;
  },
  methods: {
    async watchAdv() {
      this.$loading.show();
      const AdController = await window.Adsgram.init({
        blockId: '2093'
      });
      await AdController.show().then(result => {
        // user watch ad till the end
        // your code to reward user
        this.coin = Number(cookie.get(this.cacheKey) || 0) + 10000;
        cookie.set(this.cacheKey, this.coin);
        this.$loading.hide();
      }).catch(result => {
        // user skipped video or get error during playing ad
        // do nothing or whatever you want
        console.log(result);
        this.$loading.hide();
      });
    }
  }
};