var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.isMobile && 'wap', _vm.theme],
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    attrs: {
      "id": "ton-connect"
    }
  }), !_vm.isLoading ? _c('div', {
    staticClass: "loading"
  }, [_vm._m(0)]) : _vm._e(), _vm.isLoading && _vm.isRouterAlive ? [_c('layout')] : _vm._e()], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "breeding-rhombus-spinner"
  }, [_c('div', {
    staticClass: "rhombus child-1"
  }), _c('div', {
    staticClass: "rhombus child-2"
  }), _c('div', {
    staticClass: "rhombus child-3"
  }), _c('div', {
    staticClass: "rhombus child-4"
  }), _c('div', {
    staticClass: "rhombus child-5"
  }), _c('div', {
    staticClass: "rhombus child-6"
  }), _c('div', {
    staticClass: "rhombus child-7"
  }), _c('div', {
    staticClass: "rhombus child-8"
  }), _c('div', {
    staticClass: "rhombus big"
  })]);
}];
export { render, staticRenderFns };