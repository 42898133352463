var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "_indexPage"
  }, [_c('div', {
    staticClass: "balance"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm._v(" " + _vm._s(_vm.coin))]), _c('div', {
    staticClass: "unit"
  }, [_vm._v("Coins")])]), _c('div', {
    staticClass: "tip"
  }, [_vm._v("Watch adv to get 10K Coins")]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.watchAdv();
      }
    }
  }, [_vm._v("Watch")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };